import { Component } from 'react'
import isEqual from 'lodash/isEqual'

import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { showSnackbar } from '../../UI/Snackbar'

import {
  BROKERS, error_msg, origins,
} from '../../utils/consts'
import { pathname_mapping, appLoginRoutes } from '../../utils/navigationConstant'
import {
  deleteCookies, getCookies, setCookie, parseQuery,
  headerGenerator, getLocalStorage, getHeaderUuid,
} from '../../utils/common'
import { PREFERENCES, THEME_TYPE_MAP } from '../Profile/profileUtils'
import { ApiRequest } from '../../utils/apiRequests'
import { authRoutes } from '../../AuthenticatedRoutes'
import { commonRoutes } from '../../CommonRoutes'
import { isCtbFlow, isLoginFLow } from '../Create/Create-utils'
// import { OpenStreakKiteLoginUrl } from '../../utils/apiEndpoints'

const version = 4
const routeVersions = [3]

class Authenticator extends Component {
  state = {
    activeRoute: this.props.ROUTES.LOADING,
    isWebView: false,
    pageLoader: true,
    openWelcomeReward: false,
    showDisclosureModal: false,
    inComingBroker: '',
  }

  queryParams = {}

  uuidHeader = {}

  componentDidMount() {
    this.cookies = getCookies()
    this.getHeaders()

    const {
      location: { pathname, search }, loginUser, validateSession, fetchVersion,
      ROUTES, accountActivation, history, displayDevice,
    } = this.props
    const { isWebView } = this.state

    const isCtb = isCtbFlow()

    let refParams = {}
    let decodedParams = {}
    if (search) {
      this.queryParams = parseQuery(search)
      decodedParams = parseQuery(search, true)
    }

    let pathParams = ''
    let sharedPathname = ''
    const currentDomain = window.location.hostname
    const currentUrl = window.location.href
    const isStreakDomain = currentDomain.includes('streak.tech')
    const isStreakZerodhaDomain = currentDomain.includes('streak.zerodha.com')
    const isZerodhaLogin = pathname.includes(pathname_mapping.AppZerodhaLogin)

    const streakDomainRegex = /streak\.tech\/(.*)/g
    let streakCtbPath = ''
    if (currentUrl.match(streakDomainRegex) && currentUrl.match(streakDomainRegex).length) {
      const fullUrl = currentUrl.match(streakDomainRegex)[0]
      if (fullUrl.split('tech/') && fullUrl.split('tech/').length > 1) {
        streakCtbPath = fullUrl.split('tech/')[1]
      }
    }

    const { pathname: loginPathname, pathParams: loginPathParams } = decodedParams
    const hasQueryParams = loginPathname !== ''
    if (isStreakZerodhaDomain && isZerodhaLogin && hasQueryParams) {
      pathParams = loginPathParams
      sharedPathname = loginPathname

      if (pathParams) {
        setCookie({ pathname: sharedPathname, pathParams })
      }
    } else if (isStreakDomain && isZerodhaLogin && isCtb && streakCtbPath) {
      window.open(`https://streak.zerodha.com/${streakCtbPath}`, '_self')
      return
    }

    // if (pathname.includes(pathname_mapping.AppZerodhaLogin) && (sharedPathname)) {
    //   setCookie({ pathname: atob(sharedPathname), pathParams: atob(pathParams) })
    // }
    const {
      // vid,
      request_token, auth, code: upstoxCode, token,
      email, user_uuid, src, rb: route_broker,
      ref, ref_id, device_ref = 'mobile', // ref_id is from mobile, ref is from web url
      RequestToken, API_Session, user_id, redirect_url = '',
      // email DANGER
    } = this.queryParams
    const { create_scanner, id = '' } = decodedParams
    if (isCtb) {
      setCookie({ callType: 2 }, new Date(Date.now() + (24 * 60 * 60 * 1000)).toUTCString())
    }
    // add broker from app
    if (email && user_uuid) {
      setCookie({
        addBroker: JSON.stringify({
          email: this.queryParams.email,
          user_uuid: this.queryParams.user_uuid,
          csrf: this.queryParams.csrf,
          adding_broker: this.queryParams.adding_broker,
        }),
      })
    }
    const {
      addBroker,
    } = this.cookies

    if (pathname.includes(pathname_mapping.BrokerRedirect)) {
      window.open(redirect_url, '_self')
    }

    if (ref) {
      setCookie({
        ref_id: ref,
      })
      this.showWelcomeReward = true
    }

    if (create_scanner && id) {
      setCookie({
        create_scanner_id: id,
        create_scanner: true,
      })
      history.replace(pathname_mapping.Auth)
    }

    if (ref_id) {
      refParams = {
        ref_id,
        device: device_ref,
      }
    } else if (this.cookies.ref_id) {
      refParams = {
        ref_id: this.cookies.ref_id,
        device: displayDevice,
      }
      this.showWelcomeReward = true
    }
    let addBrokerParams = null
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-csrftoken': btoa('login'),
      ...this.uuidHeader,
    }
    if (addBroker) {
      try {
        addBrokerParams = JSON.parse(addBroker)
      } catch (e) {
        addBrokerParams = null
      }
      deleteCookies(['addBroker'])
    }
    const reqPath = pathname.match(/^\/browse\/[a-z1-9]*/g)
    const matchedBroker = reqPath && reqPath[0] ? reqPath[0].split('/')[2] : ''
    // console.log(this.cookies, this.queryParams, 'lol cookies,queryParams ')
    if (matchedBroker) {
      this.setState({ inComingBroker: matchedBroker })
      if (pathname.split(matchedBroker) && pathname.split(matchedBroker)[1] === '/pricing/offers') {
        this.setState({ activeRoute: ROUTES.AUTH })
      } else {
        this.setState({ activeRoute: ROUTES.AUTH })
      }
    } else if (request_token) {
      // if coming from kite page
      let params = {
        ...this.queryParams,
        ...refParams,
      }
      if (addBrokerParams) {
        params = {
          ...params,
          ...addBrokerParams,
          ...refParams,
        }
      }
      loginUser(params, headers)
    } else if (RequestToken) {
      // 5paisa sso login
      // const tokenKey = `5paisacookie=${this.queryParams['5paisacookie']}
      // ;JwtToken=${this.queryParams.JwtToken}`
      let params = {}
      if (addBrokerParams) {
        params = {
          service: '5paisa',
          broker: '5paisa',
          token: RequestToken,
          ...addBrokerParams,
          ...refParams,
        }
      } else {
        params = {
          service: '5paisa',
          broker: '5paisa',
          token: RequestToken,
          ...refParams,
        }
      }
      loginUser(params, headers, '5paisa')
    } else if (pathname === '/create_session' && token) {
      const params = {
        service: 'ab',
        broker: 'ab',
        ...this.queryParams,
        ...refParams,
      }
      loginUser(params, headers, 'create_session')
    } else if (API_Session) {
      // icici login
      let params = {}
      if (addBrokerParams) {
        params = {
          service: 'icici',
          broker: 'icici',
          token: API_Session,
          user_id,
          ...addBrokerParams,
          ...refParams,
          // email,
          // user_uuid,
          // csrf,
          // adding_broker,
        }
      } else {
        params = {
          service: 'icici',
          broker: 'icici',
          token: API_Session,
          user_id,
          ...refParams,
        }
      }
      loginUser(params, headers, 'icici')
    } else if (upstoxCode) {
      // upstox login
      let params = {}
      if (addBrokerParams) {
        params = {
          service: 'upstox',
          broker: 'upstox',
          token: upstoxCode,
          ...addBrokerParams,
          ...refParams,
          // email,
          // user_uuid,
          // csrf,
          // adding_broker,
        }
      } else {
        params = {
          service: 'upstox',
          broker: 'upstox',
          token: upstoxCode,
          ...refParams,
        }
      }
      loginUser(params, headers, 'upstox')
    } else {
      const {
        v_direct,
        tech_route,
      } = this.queryParams
      if (v_direct) { // coming from change version
        // console.log(v_direct)
        const tokenString = atob(v_direct)
        if (tokenString) {
          const [sessionid, csrfmiddlewaretoken] = tokenString.split(':')
          validateSession({
            csrf: csrfmiddlewaretoken,
            sessionid,
            csrfmiddlewaretoken,
          },
          headerGenerator(csrfmiddlewaretoken, sessionid))
        } else {
          showSnackbar('Your request is malfunctioned. Please login')
          // this.showSnackbar('Your request is malfunctioned. Please login')
          this.setState({ activeRoute: ROUTES.AUTH })
        }
      } else if (pathname && pathname.match(/^\/login\/[a-z1-9]*/g) && appLoginRoutes.includes(pathname.match(/^\/login\/[a-z1-9]*/g)[0])) {
        // coming from some other version or app
        if (isWebView) {
          this.setState({ activeRoute: ROUTES.MOBILE_AUTH, showPageLoader: true })
        } else {
          this.setState({ activeRoute: ROUTES.AUTH, showPageLoader: false })
        }
      } else if (this.cookies.token) {
        // if token exit check if user is in correct domain
        // console.log(this.cookies.token, 'mount token')
        if (tech_route) {
          setCookie({ pathname, pathParams: search })
          if (this.cookies.broker === route_broker) {
            fetchVersion(headerGenerator(atob(this.cookies.token), atob(this.cookies.id)))
          } else {
            deleteCookies(['token', 'id', 'broker', 'ref_id'])
            this.technicalsRouteToLogin(route_broker)
          }
        } else {
          fetchVersion(headerGenerator(atob(this.cookies.token), atob(this.cookies.id)))
        }
      } else if (pathname === '/account_activation' && auth) {
        // no token so route to kite login
        accountActivation({ auth })
      } else {
        if (pathname !== pathname_mapping.Auth) {
          setCookie({ pathname, pathParams: search })
        }
        if (tech_route) {
          this.technicalsRouteToLogin(route_broker)
        } else if (authRoutes.some(route => window.location.pathname.includes(route.path)) || ref) {
          // history.replace(pathname_mapping.Auth)
        }
        this.setState({ activeRoute: ROUTES.AUTH })
      }
    }
    this.setState({ isWebView: src === 'app' })
  }

  componentDidUpdate(prevProps) {
    const {
      loginError, loginErrorMsg, loginResp, sessionValid, sessionValidError,
      defaultVersion, fetchVersionError, fetchVersionErrorMsg, ROUTES, updateVersion,
      isLoggedIn, history, signUp, user_details, isActivating, activationError,
      activationErrorMsg, activationResp, updateVersionSuccess, updateVersionError,
      updateVersionErrorMsg, isUpdatingVersion, location,
      isLogging, toggleAuthModal, updateGA, // isFetchingUser,
    } = this.props
    // const { subscription_plan = '' } = user_details
    const { showDisclosureModal } = this.state
    if (loginError !== prevProps.loginError && loginError) {
      const msg = loginErrorMsg || 'Streak login error. Please contact support at support@streak.tech'
      if (!signUp) showSnackbar(msg, {}, 0)
      this.mobileAuthTransfer({ status: 'error', msg })
      // if not switching the broker then route to login
      if (!loginResp.csrf) {
        this.setState({ activeRoute: ROUTES.AUTH })
        history.replace(pathname_mapping.Auth)
      } else {
        // login with old broker
        this.cookies.token = null
      }
      this.setState({ showPageLoader: false })
    }
    if (isLogging !== prevProps.isLogging && isLogging && this.cookies.token) {
      // loggin in so remove any previous token
      delete this.cookies.token
      deleteCookies(['token', 'id'])
      this.setState({ showPageLoader: true })
    }
    // coming from url with id and csrf in query params
    if (!isEqual(sessionValid, prevProps.sessionValid)) {
      if (sessionValid) {
        if (defaultVersion !== version) {
          updateVersion({ csrfmiddlewaretoken: sessionValid.csrf, version },
            headerGenerator(sessionValid.csrf, sessionValid.sessionid))
        }
        this.initializeApp(sessionValid, true)
      } else if (sessionValidError) {
        let isExpired = false
        if (location.search) {
          const { session } = parseQuery(location.search)
          if (session === 'expired') {
            isExpired = true
            toggleAuthModal(true)
          }
        }
        if (!isExpired) { this.domainChange() }
      }
    } else if (loginResp.csrf && !prevProps.loginResp.csrf
      && !this.cookies.token) {
      // fresh login
      this.cookies = getCookies()
      const { broker = '', today } = this.cookies
      updateGA('Logged In', broker, '', 'Dashboard', 'LoggedIn', {}, true)
      this.mobileAuthTransfer({
        status: 'success', ...loginResp,
      })
      const todayDate = dayjs().format('DD/MM/YYYY')
      if((!today || today !== todayDate) && !showDisclosureModal) {
        this.setState({ showDisclosureModal: true })
      }
      if (routeVersions.includes(defaultVersion)) {
        // fresh login but user site version is different
        this.domainChange({
          domain: `v${defaultVersion}`, loginResp, loginWithBroker: this.cookies.broker,
        })
      } else {
        this.initializeApp(loginResp)
        if (defaultVersion !== version) {
          updateVersion({ csrfmiddlewaretoken: loginResp.csrf, version },
            headerGenerator(loginResp.csrf, loginResp.sessionid))
        }
        this.initialMount = true
      }
    } else if (defaultVersion !== prevProps.defaultVersion && this.cookies.token
      && isEmpty(user_details)) {
      const csrf = atob(this.cookies.token)
      const sessionid = atob(this.cookies.id)
      this.mobileAuthTransfer({ status: 'success', csrf, sessionid })
      const resp = loginResp.csrf ? loginResp : null
      if (routeVersions.includes(defaultVersion)) {
        // console.log(defaultVersion, 'version change update')
        this.domainChange({ domain: `v${defaultVersion}`, loginResp: resp, loginWithBroker: this.cookies.broker })
      } else {
        // broker added now fetching info with new csrf
        this.initializeApp(resp, true)
      }
    } else if (fetchVersionError !== prevProps.fetchVersionError && fetchVersionError) {
      showSnackbar(fetchVersionErrorMsg, {}, 0)
      if (fetchVersionErrorMsg && fetchVersionErrorMsg.toLowerCase() === 'auth') {
        this.logoutHandler()
      }
    } else if (prevProps.loginResp.csrf && !isEqual(loginResp, prevProps.loginResp)
      && this.cookies.token) {
      // switching or adding broker
      this.mobileAuthTransfer({
        status: 'success', ...loginResp,
      })
      if (routeVersions.includes(defaultVersion)) {
        this.domainChange({ domain: `v${defaultVersion}`, loginResp, loginWithBroker: this.cookies.broker })
      } else {
        this.initializeApp(loginResp)
      }
    }

    if (prevProps.isLoggedIn && !isLoggedIn) {
      this.logoutHandler()
    }
    if (!isActivating && prevProps.isActivating) {
      if (activationResp && activationResp.status === 'success') {
        showSnackbar('Account verified successfully', {}, 1)
        // loginUserSuccess(activationResp)
      } else if (activationError) {
        showSnackbar(activationErrorMsg || 'Account activation failed', {}, 0)
        this.setState({ activeRoute: ROUTES.AUTH })
      }
    }
    if (!isUpdatingVersion && prevProps.isUpdatingVersion) {
      if (updateVersionSuccess && updateVersionSuccess !== version) {
        this.domainChange({ domain: 'v3', loginResp, loginWithBroker: this.cookies.broker })
      } else if (updateVersionError) {
        showSnackbar(updateVersionErrorMsg, {}, 0)
      }
    }
    // if(!isEqual(user_details, prevProps.user_details) && !isFetchingUser) {
    //   if(subscription_plan === 'free' && user_details.broker === 'zerodha') {
    //     window.open(OpenStreakKiteLoginUrl, '_self')
    //   }
    // }

    if (user_details.broker && user_details.broker !== prevProps.user_details.broker) {
      setCookie({ broker: user_details.broker })
    }
    if (user_details.first_time_login && this.showWelcomeReward) {
      this.showWelcomeReward = false
      this.setState({ openWelcomeReward: true })
      deleteCookies(['ref_id'])
    }
  }

  brokeroAuthCallback = (data) => {
    console.log(data)
    try {
      const { loginUserSuccess, loginUserFailure } = this.props
      const loginResp = JSON.parse(data)
      if (!loginResp.error && loginResp.status === 'success') {
        loginUserSuccess(loginResp)
      } else {
        loginUserFailure(loginResp.error || error_msg)
      }
    } catch (err) {
      console.log(err, 'login errir')
      showSnackbar('Some error occurred')
    }
    this.setState({ showPageLoader: false })
  }

  technicalsRouteToLogin = (brokerName) => {
    // const { history } = this.props
    switch (brokerName) {
      case BROKERS.ZERODHA.name: {
        setCookie({ broker: BROKERS.ZERODHA.name })
        //  const webviewUrl = isConnectingBroker ? kite_loginUrl + linkingParams : loginUrl
        this.domainChange({ loginWithBroker: BROKERS.ZERODHA.name })
        break
      }

      case BROKERS.ANGEL.name: {
        setCookie({ broker: BROKERS.ANGEL.name })
        this.domainChange({ loginWithBroker: BROKERS.ANGEL.name })
        break
      }
      case BROKERS.UPSTOX.name: {
        setCookie({ broker: BROKERS.UPSTOX.name })
        this.domainChange({ loginWithBroker: BROKERS.UPSTOX.name })
        break
      }
      case BROKERS.FIVE_PAISA.name: {
        setCookie({ broker: BROKERS.FIVE_PAISA.name })
        this.domainChange({ loginWithBroker: BROKERS.FIVE_PAISA.name })
        break
      }
      case BROKERS.ICICI.name: {
        setCookie({ broker: BROKERS.ICICI.name })
        this.domainChange({ loginWithBroker: BROKERS.ICICI.name })
        break
      }
      default: break
    }
  }

  toggleBrokerAuth = (brokerLoginUrl) => {
    if (!this.brokerAuthentication) {
      const left = (window.screen.availWidth / 2) - 225
      const top = (window.screen.availHeight / 2) - 250
      this.brokerAuthentication = window
      this.brokerAuthentication.brokeroAuthCallback = this.brokeroAuthCallback
      this.brokerAuthentication.onclose = () => {
        this.brokerAuthentication = null
        showSnackbar('Login cancelled by user')
        this.setState({ showPageLoader: false })
      }
      this.brokerAuthentication.open(
        brokerLoginUrl,
        'brokerAuthentication',
        `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=450,height=500,left=${left},top=${top}`,
      )
    } else {
      this.brokerAuthentication.location.href = brokerLoginUrl
      this.brokerAuthentication.focus()
    }
    this.setState({ showPageLoader: true })
  }

  getHeaders = () => {
    const appUUID = getHeaderUuid()
    this.uuidHeader = { 'X-APP-UUID': appUUID }
  }

  domainChange = (params = {}) => {
    const {
      domain, loginResp, target = '_self', loginWithBroker, showBrokerAuth,
    } = params
    const { user_details } = this.props
    let brokerName = loginWithBroker || user_details.broker
    if (window.location.hostname === BROKERS.ZERODHA.hostnames[0]) {
      brokerName = BROKERS.ZERODHA.name
    }
    if (domain && brokerName) {
      let query = {}
      if (process.env.NODE_ENV !== 'development') {
        deleteCookies(['token', 'id', 'broker'])
      }
      if (loginResp) {
        query = `${loginResp.sessionid}:${loginResp.csrf}`
      } else {
        query = `${atob(this.cookies.id)}:${atob(this.cookies.token)}`
      }
      const newDomain = origins[domain](brokerName)
      const defaultQuery = this.cookies.pathParams
        ? `${this.cookies.pathname}${this.cookies.pathParams}` : ''
      if (defaultQuery) {
        deleteCookies(['pathname', 'pathParams'])
        window.open(`${newDomain}?v_direct=${btoa(query)}&redirect_url=${encodeURIComponent(defaultQuery)}`, target)
      } else {
        window.open(`${newDomain}?v_direct=${btoa(query)}`, target)
      }
      return null
    }
    const isCtb = isCtbFlow('', true)
    const isIOS = navigator.userAgent && navigator.userAgent.includes('iPhone')
    const isLogin = isLoginFLow()
    if (isIOS && (isCtb || isLogin)) {
      setTimeout(() => {
        this.brokerRedirect(brokerName, showBrokerAuth, isCtb)
      }, 6000)
      return null
    }
    this.brokerRedirect(brokerName, showBrokerAuth, isCtb)

    return null
  }

  brokerRedirect = (brokerName, showBrokerAuth) => {
    const { history } = this.props
    switch (brokerName) {
      case BROKERS.ZERODHA.name: {
        if (showBrokerAuth) {
          this.toggleBrokerAuth(origins.kite)
        } else window.open(origins.kite, '_self'); break
      }
      case BROKERS.FIVE_PAISA.name: {
        if (showBrokerAuth) {
          this.toggleBrokerAuth(origins.paisa)
        } else window.open(origins.paisa, '_self'); break
      }
      case BROKERS.ANGEL.name: {
        const appUuid = getHeaderUuid()
        const OS = navigator.platform
        const abUrl = `${origins.ab}?ApplicationName=streak&OS=${OS}&AppID=${appUuid}`
        if (showBrokerAuth) {
          this.toggleBrokerAuth(abUrl)
        } else window.open(abUrl, '_self'); break
        // this.setState({ activeRoute: ROUTES.AUTH }); break
      }
      case BROKERS.UPSTOX.name: {
        if (showBrokerAuth) {
          this.toggleBrokerAuth(origins.upstox)
        } else window.open(origins.upstox, '_self'); break
      }
      case BROKERS.ICICI.name: {
        if (showBrokerAuth) {
          this.toggleBrokerAuth(origins.icici)
        } else window.open(origins.icici, '_self'); break
      }
      default: {
        history.replace(pathname_mapping.Auth)
        deleteCookies(['token', 'id', 'broker'])
        break
      }
    }
  }

  mobileAuthTransfer = (resp) => {
    /* eslint-disable no-underscore-dangle */
    try {
      if (window.__REACT_WEB_VIEW_BRIDGE) {
        window.__REACT_WEB_VIEW_BRIDGE.postMessage(JSON.stringify(resp))
      } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(resp))
      } else {
        window.postMessage(JSON.stringify(resp), '*')
      }
      if (window.opener && !window.opener.closed && window.opener.brokeroAuthCallback) {
        // window.opener.location.href = `${pathname_mapping.Accounts}&${search.substring(1)}`
        window.opener.brokeroAuthCallback(JSON.stringify(resp))
        window.opener.focus()
        window.close()
      }
    } catch (err) {
      console.log(err.message, 'auth callback')
    }
  }

  initializeApp = (params, shouldUpdateToken) => {
    const {
      setToken,
      history,
      ROUTES,
      getUserDetails,
      location,
      toggleTheme,
      fetchBilling,
    } = this.props
    const {
      plan,
      // period,
      // renew,
      // planMeta,
      // homeRoute,
      id,
      utm_source,
      subscribe,
    } = this.queryParams
    this.setState({ activeRoute: ROUTES.APP })
    this.cookies = getCookies() || {}
    this.unAuthorized = false
    const {
      pathname: savedPathname = '', pathParams = '', create_scanner = '', create_scanner_id = '',
    } = this.cookies
    let routeTo = savedPathname ? { pathname: savedPathname } : ''
    let sessionid = ''
    let csrf = ''
    if (params) {
      // if token does not exist in the cookie
      ({
        sessionid, csrf,
      } = params)
      this.headers = headerGenerator(csrf, sessionid)

      // user clicked on home page subscribe and no token is set
      // it is done like this as before the all the component
      // was mounted then check for login was done
      // if (this.cookies.homeRoute && this.cookies.homePeriod
      //   && this.cookies.homeRenew && this.cookies.homePlanMeta) {
      //   updateHomeRouteMetadata({
      //     plan: atob(this.cookies.homePlan),
      //     period: atob(this.cookies.homePeriod),
      //     renew: atob(this.cookies.homeRenew),
      //     planMeta: atob(this.cookies.homePlanMeta),
      //   })
      //   this.homeRoute = true
      //   deleteCookies(['homeRoute', 'homePlan', 'homePeriod', 'homeRenew', 'homePlanMeta'])
      // }
      if (subscribe && plan) {
        history.replace(pathname_mapping.pricing)
      }
      if (pathParams) {
        const {
          id: savedId, utm_source: savedUtmSrc,
        } = parseQuery(pathParams, true)
        if (savedPathname === pathname_mapping.popular && savedId) {
          routeTo = this.routeToScanDetails({ id: savedId, csrf })
        } else {
          routeTo = { pathname: savedPathname, pathParams }
        }
        if (savedUtmSrc) {
          this.setPartnerRef({ utm_source: savedUtmSrc, csrf }, this.headers)
        }
      } else if (create_scanner && create_scanner_id) {
        routeTo = this.routeToScanDetails({ id: create_scanner_id, csrf, create_scanner })
      }
      this.cookies = {
        token: btoa(csrf),
        id: btoa(sessionid),
      }
      setCookie(this.cookies)
      // this.setState({ activeRoute: ROUTES.APP }, () => setCookie(this.cookies))
    } else {
      // if token exist in the cookie
      csrf = atob(this.cookies.token)
      sessionid = atob(this.cookies.id)
      this.headers = headerGenerator(csrf, sessionid)

      if (location.pathname === pathname_mapping.popular && id) {
        routeTo = this.routeToScanDetails({ id, csrf })
      } else if (savedPathname) {
        routeTo = { pathname: savedPathname, pathParams }
      } else if (create_scanner && create_scanner_id) {
        routeTo = this.routeToScanDetails({ id: create_scanner_id, csrf, create_scanner })
      }
      if (utm_source) {
        this.setPartnerRef({ utm_source, csrf }, this.headers)
      }
    }
    deleteCookies(['pathname', 'pathParams', 'create_scanner', 'create_scanner_id'])

    if (shouldUpdateToken) {
      setToken(this.cookies)
    }

    getUserDetails({ csrf, sessionid })

    const themeMode = getLocalStorage(PREFERENCES.THEME)

    if (themeMode && (
      authRoutes.some(route => location.pathname.includes(route.path))
      || commonRoutes.some(route => location.pathname.includes(route.path))
    )) {
      toggleTheme(themeMode)
    }
    // common to
    if ((savedPathname === pathname_mapping.Auth
      || location.pathname.match(/^\/login[/a-z1-9]*/g)
      || pathname_mapping.AccountActivation === location.pathname)
      && !routeTo
    ) {
      history.replace(pathname_mapping.Dashboard)
    } else if (routeTo && routeTo.pathname && !routeTo.pathname.match(/^\/login[/a-z1-9]*/g)) {
      if (authRoutes.some(route => routeTo.pathname.includes(route.path))
        || commonRoutes.some(route => route.path === routeTo.pathname)) {
        history.replace({
          pathname: routeTo.pathname,
          search: routeTo.pathParams,
        })
      } else {
        history.replace(pathname_mapping.Dashboard)
      }
    }
    // getNotifications(this.headers)
    // const { pathname } = location
    // const validPaths = Object.values(pathname_mapping)
    // if (!validPaths.includes(pathname) || pathname === '/login') {
    //   if (activeRoute !== ROUTES.APP) {
    //     this.setState({ activeRoute: ROUTES.APP }, () => {
    //       history.replace(pathname_mapping.Dashboard)
    //     })
    //   } else {
    //     history.replace(pathname_mapping.Dashboard)
    //   }
    // for upgrade popup
    const isCtb = isCtbFlow()

    if (!isCtb) {
      fetchBilling()
    }
    this.setState({ showPageLoader: false })
  }

  setPartnerRef = ({ utm_source, csrf }, headers) => {
    const { setPartnerRef } = this.props
    setPartnerRef({ partner_ref: utm_source, csrfmiddlewaretoken: csrf }, headers)
  }

  routeToScanDetails = ({ id, create_scanner }) => {
    const discoverSearch = create_scanner ? `?id=${btoa(id)}&tab=scan_details_discover&sample=${btoa(true)}&create_scanner=${btoa(true)}` : `?id=${btoa(id)}&tab=scan_details_discover&sample=${btoa(true)}`
    return ({
      pathname: pathname_mapping.Scanners,
      pathParams: discoverSearch,
    })
  }

  routeToBacktestDetails = ({ pathname, search }) => {
    return ({
      pathname,
      search,
    })
  }

  routeAlgoDiscover = ({ pathname, search }) => {
    return ({
      pathname,
      search,
    })
  }

  logoutHandler = (redirect) => {
    const {
      logout,
      // unSub,
      ROUTES,
      clearUserDetails,
      history,
      toggleTheme,
    } = this.props
    this.cookies = {}
    // if (this.worker) {
    //   this.worker.postMessage({
    //     type: 'logout',
    //   })
    // }
    if (redirect) {
      deleteCookies(['token', 'id', 'broker', 'ref_id'])
      window.open('https://pro.streak.tech', '_self')
    } else {
      this.setState({ activeRoute: ROUTES.AUTH }, () => {
        toggleTheme(THEME_TYPE_MAP.LIGHT.value)
        deleteCookies(['token', 'id', 'broker', 'ref_id'])
        // unSub(true)
        clearUserDetails()
        logout()
        this.cookies = {}
        this.queryParams = {}
        history.push(pathname_mapping.Auth)
        ApiRequest.defaults.headers.common.Authorization = ''
      })
    }
  }

  switchDomain = () => {
    const { loginResp, updateVersion } = this.props
    if (loginResp.csrf) {
      updateVersion({ csrfmiddlewaretoken: loginResp.csrf, version: 3 })
      showSnackbar('Updating version')
    }
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onDismissWelcomeReward = (modalKey) => {
    const { updateGA } = this.props
    updateGA('Welcome', 'Close', 1, 'Pop-ups')
    this.setState({ [modalKey]: false })
  }

  onDismissDisclosureModal = () => {
    const today = dayjs().format('DD/MM/YYYY')
    setCookie({ today })
    this.setState({ showDisclosureModal: false })
  }

  render() {
    const { children } = this.props
    const {
      activeRoute, isWebView, showPageLoader,
      openWelcomeReward, inComingBroker, showDisclosureModal,
    } = this.state

    return children({
      activeRoute,
      domainChange: this.domainChange,
      switchDomain: this.switchDomain,
      showPageLoader,
      isWebView,
      openWelcomeReward,
      toggleModal: this.toggleModal,
      onDismissWelcomeReward: this.onDismissWelcomeReward,
      showDisclosureModal,
      onDismissDisclosureModal: this.onDismissDisclosureModal,
      inComingBroker,
    })
  }
}

export default Authenticator
