import { apiInstance } from './helpers/apiInstance'

export const SITE_KEY = process.env.NODE_ENV === 'development' ? 'qh2hb38gqs09kbp6' : '7ma75l25k7krhr2t'

export const OPEN_SITE_KEY = '2b0bx36bnep2vslf'
// process.env.NODE_ENV === 'development' ? 'qh2hb38gqs09kbp6' : 'g0sjffygw9m77dnq'
// v4-dev '7ma75l25k7krhr2t'
// 'ade436zjl80jflpc' // FOR NINJA:  g0sjffygw9m77dnq

export const prodEndpoints = {
  login: 'login/', // PLACE ORDER API(headers added)
  ams_login: 'ams_login/', // PLACE ORDER API(headers added)
  broker_sso_login: 'broker_sso_login/', // PLACE ORDER API(headers added)
  ams_logout: 'ams_logout/',
  validate_session: 'update_session/',
  user_version_pref: 'user_version_pref/',
  validate_email: 'validate_email/',
  user_login: 'user_login/',
  account_activation: 'account_activation/',
  register_partner: 'register_partner/',
  update_to_ultimate: 'update_to_ultimate/',
  reset_password: 'reset_password/',
  change_password: 'change_password/',
  forgot_password: 'forgot_password/',
  ams_create_session: 'ams_sso_login/',

  backTest: 'backtest/',
  saveBacktest: 'save_backtest_params/',
  backtest_service: 'backtest_service',
  market_backtest_service: 'market_backtest_service',
  backtest_service_async: 'backtest_service_async',
  market_backtest_service_async: 'market_backtest_service_async',
  isAlgoDeployed: 'is_algorithm_deployed/',
  editAlgo: 'algorithm/',
  get_subscription_limit: 'get_subscription_limit/',
  submit_algorithm: 'submit_algorithm/',
  // deployAlgo: 'deploy_algorithm_multi/',
  deployAlgo: 'deploy_algorithm_multi2/',
  fetch_open_positions: 'fetch_open_positions/',
  exit_position_now_force_stop: 'exit_position_now_force_stop/', // PLACE ORDER API(headers added)
  exit_with_positions_open: 'exit_with_positions_open/', // PLACE ORDER API(headers added)
  exit_all: 'exit_all/', // PLACE ORDER API(headers added)
  get_live_algos: 'get_live_algos/',
  cancel_order_click: 'cancel_order_click/', // PLACE ORDER API(headers added)
  fetch_order_book: 'fetch_order_book/', // revert to relative domain later
  fetch_positions: 'fetch_positions/', // revert to relative domain later
  fetch_holdings: 'fetch_holdings/', // revert to relative domain /fech_holdings
  fetch_specific_position: 'fetch_specific_position/', // revert to relative domain
  get_instrument_token: 'https://s.streak.tech/instruments/exact_search_ninja/symbols',
  get_instrument_token_open: 'https://s-op.streak.tech/instruments/exact_search_ninja/symbols',
  instrument_search: 'https://s.streak.tech/instruments/basic_search_ninja',
  instrument_search_discover: 'https://sr.streak.tech/discover/symbol',
  filter_algo: 'https://s.streak.tech/algorithms/filter_search',
  multi_search: 'https://s.streak.tech/algorithms/multi_search',
  getAlgoState: 'strategy/',
  deleteAlgo: 'algorithm_remove/',
  notifications: 'notifications_handler/',
  notifications_count: 'notifications_count/', // remove https://api.streak.ninja later
  mark_used: 'mark_notification_used/',
  mark_notifications_used: 'mark_notifications_used/',
  place_order_tpsl: 'place_order_tpsl/', // PLACE ORDER API(headers added)
  place_order: 'place_order/', // PLACE ORDER API(headers added)
  place_order_discp: 'place_order_discipline/', // PLACE ORDER API(headers added)
  place_direct: 'place_order_direct/', // PLACE ORDER API
  feedback: 'submit_feedback/',
  search_algo: 'https://s.streak.tech/algorithms/basic_search',
  get_session_status: 'get_session_status/',
  redeploy_algorithm: 'redeploy_algorithm/',
  user_baskets: 'user_baskets/',
  save_profile: 'save_profile_change/',
  update_additional_info: 'update_additional_info/',
  remove_profile_pic: 'https://refapi.streak.tech/documents/delete_profile_pic',
  fetch_billing: 'fetch_billing_data/',
  billing_page_offer: 'billing_page_offer/',
  start_subscription: 'start_subscription/',
  cancel_subscription: 'cancel_subscription/',
  submit_unsubscribe_feedback: 'submit_unsubscribe_feedback/',
  get_csrf: 'gen_csrf/',
  show_order_details: 'show_order_details/',
  fetch_funds: 'fetch_dashboard_funds/',
  fetch_sample_algo: 'fetch_samples/',
  fetch_top_performers: 'fetch_top_performers/',
  clone_sample: 'clone_sample/',
  get_version: 'get_version4/',

  get_pricing: 'get_pricing5/',
  receive_subscribe_receipt: 'receive_subscribe_receipt/',
  upload_profile_pic: 'https://refapi.streak.tech/documents/upload_profile_pic',
  download_profile_pic: 'https://refapi.streak.tech/documents/download_profile_pic',
  profile_carousel: 'profile_carousel/',

  accept_terms: 'accept_terms/',
  get_terms: 'fetch_terms/',
  app_trading_terms: 'app_trading_terms/',
  app_terms: 'app_terms/',
  app_disclosure: 'app_disclosure/',
  app_privacy: 'app_privacy/',
  signup: 'save_lead/',
  generate_otp: 'generate_otp/',
  generate_otp2: 'generate_otp2/',
  verify_phone_number: 'verify_phone_number/',
  save_phone_number: 'save_phone_number/',
  indicators: 'v2/fetch_parsing_tree/',
  whats_new: 'v2/whats_new/',
  fetch_order_log: 'fetch_order_log/',
  trainer_model: 'fetch_trainer_model/',
  stop_waiting_algos: 'stop_waiting_algos/',
  archives: 'https://s.streak.tech/archives/search',
  algos: 'algos/',
  screener: 'screener/',
  shared_screener: 'shared_screener/',
  screener_trainer: 'fetch_sctrainer_model/',
  favorite_screener: 'favorite_screener/',
  remove_screener: 'remove_screener/',
  screener_tree: 'screener_tree/',
  get_alert_screens: 'get_alerts',
  stop_alert_screens: 'stop_alert',
  set_alert_screens: 'set_alert',
  get_alert_history: 'get_alert_history',
  screener_tags: 'screener_tags/',
  search_scans: 'https://s.streak.tech/screeners/basic_search',
  // get_screens: 'https://s.streak.tech/screeners/filter_search',
  get_screens: 'https://s.streak.tech/screeners/multi_search',
  request_demo: 'request_demo/',
  check_request_demo: 'check_request_demo/',
  generate_subscription: 'generate_subscription/',
  get_subscription_price: 'get_subscription_price/',
  paymentProcess: 'paymentProcess/',
  fetch_payment_params: 'fetch_order_preference/',
  fetch_payment_method: 'get_payment_methods/',
  fetch_payment_preference: 'fetch_payment_preference/', // fetch saved card as contact info is provided

  fetch_backtest_chart: 'fetch_backtest_chart/',
  fetch_marketplace_chart: 'fetch_marketplace_chart/',
  marketplace_backtest: 'marketplace_backtest/',
  submit_for_publication: 'submit_for_publication/',
  marketplace: 'marketplace/',
  marketplace_tags: 'marketplace_tags/',
  follow_deployment: 'follow_deployment/',
  clone_published: 'clone_published/',
  subscribed_algos: 'my_subscribed_algos/',
  subscribeAlgo: 'subscribe_to_algo/',
  publication_action: 'publication_action/',
  marketplace_deploy: 'marketplace_deploy/',
  filter_subscribed_algos: 'https://s.streak.tech/subscribed_algorithms/multi_search',

  dashboard_discover: 'dashboard_discover/',
  tech_analysis: 'https://mo.streak.tech/api/tech_analysis/', // single
  analysis: 'https://mo.streak.tech/api/tech_analysis_multi/',
  resistance_analysis: 'https://mo.streak.tech/api/sr_analysis_multi/',
  discover_watch_list: 'discover_watch_list/',
  scanner_tree: 'https://api.streak.tech/fetch_parsing_tree_main/',
  // 'https://s.streak.tech/screeners/tree/',
  copy_screener: 'copy_screener/',
  get_invoice: 'https://refapi.streak.tech/documents/subscription_invoice/download',

  // notifications
  register_notification: 'v1/devices',

  partialAlgos: 'partial_complete/', // fetches both partial algos and scanners
  recommendations: 'dashboard_recommendations/', // fetches details for banner card (dashboard)
  depoyed_count: 'deployed_count2/', // fetches only count of active deployed items
  dashboard_carousel: 'dashboard_carousel',

  // marketwatch
  // market_watch: 'market_watch/', // old
  market_watch: 'v2/market_watch/',
  // share feat
  generate_shareable_link: 'generate_shareable_link/',
  generate_keys: 'with_base64_string/',
  clone_shared: 'clone_shared/',

  generate_scanner_share_link: 'generate_scanner_shareable_link/',
  top_gainers: 'https://datastore.streak.tech/top_gainers/',
  copy_algorithm: 'copy_algorithm/',
  generate_strategy_shareable_link: 'generate_strategy_shareable_link/',
  // referral
  get_all_referrals: 'get_all_referrals/',
  sync_contacts: 'sync_contacts/',
  avail_reward: 'avail_reward/',
  update_onboarding_activity: 'update_onboarding_activity/',
  // fetch_ctb_deploy_algo: 'http://localhost:5010/format/fetch_deploy_algo',
  fetch_ctb_deploy_algo: 'https://ctb.streak.tech/format/fetch_deploy_algo',

  order_charges: 'order_charges/',

}

export const wpEndpoints = {
  posts: '/posts',
  pages: '/pages',
}

// when working on staging environment IN_PRODUCTION is false
export const IN_PRODUCTION = false // process.env.NODE_ENV !== 'development'
export const domain = IN_PRODUCTION ? 'tech' : 'ninja'
// IN_PRODUCTION ? 'tech' : 'ninja'
export const fetchCandlesURL = {
  india: 'https://datastore.streak.tech/candles/fetch/', // also referred as tech platform
  world: 'https://datastore.streak.world/candles/fetch/',
}

export const baseUrl = `https://${apiInstance}.streak.${domain}/`

export const openUrl = 'https://api-op.streak.tech/'

export const shareableLinks = 'https://api-v2.streak.ninja/shareable_links/'

export const wpBaseUrl = 'https://blog.streak.tech/wp-json/wp/v2'

export const kiteUrl = 'https://kite.zerodha.com/'
export const upstoxUrl = 'https://pro.upstox.com/'

// backtest Service Urls
export const prodBtService = 'https://bt2.streak.tech/'
export const prodBtFastService = 'https://btfast.streak.tech/'
export const prodBtServiceAsync = 'https://btfast.streak.tech/backtest_service_async'
export const prodSbtFastService = 'https://btfast.streak.tech/market_backtest_service'
export const prodSbtService = 'https://bt2.streak.tech/market_backtest_service'
// other api urls
export const shareUrl = 'https://public.streak.tech/'
export const alertScreenUrl = 'https://scan.streak.tech/api/'
export const notificationServerURL = 'https://ns.streak.tech/'
export const scannerUrl = 'https://scan.streak.tech/api/screener'
// export const notifUrl = 'wss://nt.streak.tech/notification'
export const notifUrl = 'https://notif.streak.tech/notification'
// export const notifUrl = 'wss://nt.streak.ninja/notification'

// website url links
export const webBaseUrl = IN_PRODUCTION ? 'https://streak.tech/' : 'https://v4.streak.ninja/'
// export const webBaseUrl = 'https%3A%2F%2Fstreak.tech%2Flogin%2Fupstox'

export const openStreakUrl = IN_PRODUCTION ? 'https://streak.tech/' : 'https://open.streak.ninja/'

export const termsPage = '/terms'
export const signupPage = '/signup'
export const privacyPage = '/privacy'
export const helpPageUrl = 'https://help.streak.tech/' // 'https://help.streak.ninja/'
export const embedUrl = 'https://embed.streak.tech/'

export const loginUrl = `https://api.streak.${domain}/broker_login_url`
export const pollUrl = 'https://notif.streak.tech/handle_post/'
export const newPollUrl = `https://ua.streak.${domain}/handle_post/`
export const supportUrl = 'https://support.streak.ninja/streak_connect/admin/'
export const ctbHelpUrl = 'https://help.streak.tech/ctb/'
export const mailToSupportUrl = 'mailto:support@streak.tech'
// export const baseChartUrl = 'http://localhost:3001/'
export const baseChartUrl = 'https://wv-charts-streak.web.app/'

export const symbolIconsUrl = 'https://streak-in-public-static.s3.ap-south-1.amazonaws.com/'

export const s3Bucket = 'https://streak-public-assets.s3.ap-south-1.amazonaws.com/'

export const termsAndCondsUrl = `${baseUrl}${prodEndpoints.app_trading_terms}`

export const abSignUp = 'https://www.angelbroking.com/streak?utm_source=web&utm_medium=web&utm_campaign=AB-Site-streak'
export const abLoginHome = 'https://trade.angelone.in'
export const s3BucketMap = {
  indicators: 'images/indicators/',
}

export const changelogsEndpoint = IN_PRODUCTION ? 'streak-changelogs' : 'streak-changelogs'

// export const upstoxLoginUrl = IN_PRODUCTION
//   ? `https://api.upstox.com/index/dialog/authorize?apiKey=115YlK3Wxl1C0ZYPYttwFajmV33V8FPEabOzu68t&redirect_uri=${webBaseUrl}login/upstox&response_type=code`
//   : `https://api.upstox.com/index/dialog/authorize?apiKey=icmd1si662nXzeZoM5vi6ucJHYjeHMCa6lIM8wJ7&redirect_uri=${process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080' : webBaseUrl}/login&response_type=code`
export const upstoxLoginUrl = IN_PRODUCTION
  ? 'https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=6dd24708-a075-4f8f-98a8-f22fc25d5762&redirect_uri=https://www.streak.tech/login/upstox-v2'
  : 'https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=6dd24708-a075-4f8f-98a8-f22fc25d5762&redirect_uri=https://www.streak.tech/login/upstox-v2'
  // ? 'https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=568b8591-d334-4b43-ab91-1911cfabccd7&redirect_uri=https://www.streak.tech/login/upstox-v2'
  // : 'https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=568b8591-d334-4b43-ab91-1911cfabccd7&redirect_uri=https://www.streak.tech/login/upstox-v2'
  // ? `https://api.upstox.com/index/dialog/authorize?apiKey=115YlK3Wxl1C0ZYPYttwFajmV33V8FPEabOzu68t&redirect_uri=${webBaseUrl}login/upstox&response_type=code`
  // : `https://api.upstox.com/index/dialog/authorize?apiKey=icmd1si662nXzeZoM5vi6ucJHYjeHMCa6lIM8wJ7&redirect_uri=${process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080' : webBaseUrl}/login&response_type=code`

export const kiteLoginUrl = `https://kite.zerodha.com/connect/login?api_key=${SITE_KEY}`

export const OpenStreakKiteLoginUrl = `https://kite.zerodha.com/connect/login?api_key=${OPEN_SITE_KEY}`

export const paisaLoginUrl = IN_PRODUCTION ? 'https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=gMSIZWU3TsCel77NIKFpFAREQiMAPJ8S&ResponseURL=https://streak.tech/login/5paisa' : 'https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=gMSIZWU3TsCel77NIKFpFAREQiMAPJ8S&ResponseURL=https://v4.streak.ninja/login/5paisa'

export const academyBaseUrl = 'http://academy.streak.tech/'

export const btWasmUrl = 'https://streak-public-assets.s3.ap-south-1.amazonaws.com/bt_dist/lib.wasm.gz'

export const iciciLoginUrl = IN_PRODUCTION ? 'https://api.icicidirect.com/apiuser/login?api_key=9Sp2941D4Z2%2B1%5E9199Y1k922120Fz810' : 'https://api.icicidirect.com/apiuser/login?api_key=5%254n29l%4099237499%60G!973337013E9R1' // 'https://uatapi.icicidirect.com/apiuser/login?api_key=%23!621H441o858%600w00L7578788f7R60~'

export const abLoginUrl = 'https://login.angelbroking.com/st'
