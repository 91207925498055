/* eslint-disable */
import { put, call, takeEvery } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  getInstrumentsSuccess,
  getInstrumentsFailure,
  getExchangeTokensSuccess,
  getExchangeTokensFailure,
} from './actions'
import {
  GET_INSTRUMENTS_INIT,
  GET_EXCHANGE_TOKEN_INIT,
} from './actionTypes'
/* eslint-disable-next-line import/no-cycle */
import store from '../../store'
import { error_msg } from '../../utils/common'
import { BROKERS } from '../../utils/consts'
import { isCtbFlow } from '../Create/Create-utils'

function* getInstruments(action) {
  try {
    const { params, screenName } = action
    const state = store.getState()
    if(state?.auth?.user_details?.broker === BROKERS.ZERODHA.name && !isCtbFlow()) {
      const url = prodEndpoints.get_instrument_token
      const data = yield call(
        postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false,
      )
      if (!data.error) {
        yield put(getInstrumentsSuccess(data, screenName))
      } else {
        yield put(getInstrumentsFailure(data.error))
      }
    } else {
      yield put(getInstrumentsSuccess({ data: params?.symbols || [] }, screenName))
    }
  } catch(err) {
    yield put(getInstrumentsFailure(err.message || error_msg))
  }
}

export function* getInstrumentsSaga() {
  yield takeEvery(GET_INSTRUMENTS_INIT, getInstruments)
}

function* getExchangeTokens(action) {
  try {
    const { params, screenName } = action
    const state = store.getState()
      const url = prodEndpoints.get_instrument_token
      const data = yield call(
        postRequest, url, JSON.stringify(params), { 'content-type': 'application/json' }, false,
      )
      if (!data.error) {
        yield put(getExchangeTokensSuccess(data, screenName))
      } else {
        yield put(getExchangeTokensFailure(data.error))
      }
  } catch(err) {
    yield put(getExchangeTokensFailure(err.message || error_msg))
  }
}

export function* getExchangeTokenSaga() {
  yield takeEvery(GET_EXCHANGE_TOKEN_INIT, getExchangeTokens)
}
