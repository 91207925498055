import React from 'react'
import { withTheme } from '../Theme/ThemeProvider'

import {
  COLORS, ICONS, SPACING, theme,
} from '../Theme'
import Icon from './Icon'
import CustomText from './CustomText'
import Button from './Button'

const rules = [' 9 out of 10 individual traders in equity Futures and Options Segment, incurred losses.',
  'On an average, loss makers registered net trading loss close to ₹50,000.',
  'Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.',
  'Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.']

const sebiLink = 'https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html'

const DiscloureModal = (props) => {
  const { styles, toggleDisclosureModal } = props
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon name={ICONS.NOTIF_PAPER} size={SPACING.SPACE_20} color={theme.text} />
        <CustomText size="medium_1" weight="semi_bold">Risk disclosures on derivatives</CustomText>
      </div>
      <div className={styles.content}>
        {rules.map(rule => (
          <div key={rule} className={styles.rule}>
            <div>
              <div className={styles.diskIcon} />
            </div>
            <CustomText>{rule}</CustomText>
          </div>
        ))}
        <div className={styles.source}>
          <CustomText size="small_0" color={COLORS.BLACK_500}>
            Source:
            {' '}
            <a href={sebiLink} rel="noreferrer" target="_blank" className={styles.sourceLink}>
              SEBI
            </a>
            {' '}
            study dated January 25, 2023 on &quot;Analysis of Profit and Loss of
            Individual Traders dealing in equity Futures and Options (F&O) Segment&quot;,
            wherein Aggregate Level findings are based on annual Profit/Loss incurred
            by individual traders in equity F&O during FY 2021-22.
          </CustomText>
        </div>
      </div>
      <Button
        onPress={toggleDisclosureModal}
        buttonColor="blue"
        btnStyles={styles.confirmButton}
        text="I understand"
      />
    </div>
  )
}

const stylesheet = ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
    paddingBottom: SPACING.SPACE_10,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  rule: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    gap: SPACING.SPACE_10,
  },
  diskIcon: {
    width: SPACING.SPACE_6,
    height: SPACING.SPACE_6,
    marginTop: SPACING.SPACE_6,
    borderRadius: '50%',
    backgroundColor: theme.text,
  },
  source: {
    margin: `${SPACING.SPACE_10} 0 `,
    paddingLeft: SPACING.SPACE_16,
  },
  confirmButton: {
    alignSelf: 'flex-end',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    marginTop: SPACING.SPACE_16,
  },

})

export default withTheme(stylesheet)(DiscloureModal)
